<template>
  <button
    :id="buttonId"
    ref="ssoButtonTile"
    :disabled="disabled"
    type="button"
    class="disabled:opacity-70 disabled:cursor-not-allowed cursor-pointer py-[0.4rem] px-4 text-base flex text-center rounded-lg text-dark font-normal border border-solid border-gray-400"
    :class="[conditionalClasses]"
    @click.self="redirect"
  >
    <nuxt-img
      :src="ssoIcon"
      class="absolute"
      width="25"
      height="25"
      alt="SSOTile"
      @click="$refs.ssoButtonTile.click()"
    />
    <span
      class="m-auto"
      @click="$refs.ssoButtonTile.click()"
    >
      {{ ssoBtnPreText }} with {{ ssoAuthLabelFormatted }}
    </span>
  </button>
</template>

<script setup>
import memberhubConstants from '~/constants/memberhub.constants'
const { $tracking, $bus } = useNuxtApp()

defineOptions({
  name: 'AtomsButtonSSOTile'
})

const props = defineProps({
  fullWidth: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  targetUrl: {
    type: String,
    default: null
  },
  ssoType: {
    type: Number,
    default: memberhubConstants.GOOGLE_AUTH,
    validator (val) {
      return !![memberhubConstants.GOOGLE_AUTH, memberhubConstants.FACEBOOK_AUTH, memberhubConstants.LINKEDIN_AUTH, memberhubConstants.APPLE_AUTH].includes(val)
    }
  },
  enableTracking: {
    type: Boolean,
    default: true
  },
  ssoBtnPreText: {
    type: String,
    default: 'Join'
  }
})
const attr = useAttrs()
const emits = defineEmits(['click'])
const ssoButtonTile = ref(null)
const conditionalClasses = computed(() => {
  let classes = ''
  if (props.fullWidth) {
    classes += 'w-full '
  } else {
    classes += 'w-72 '
  }
  switch (props.ssoType) {
    case memberhubConstants.GOOGLE_AUTH:
      classes += 'bg-white text-dark'
      break
    case memberhubConstants.FACEBOOK_AUTH:
      classes += 'bg-blue-fb text-white'
      break
    case memberhubConstants.LINKEDIN_AUTH:
      classes += 'bg-blue-linked-in text-white'
      break
    case memberhubConstants.APPLE_AUTH:
      classes += 'bg-black text-white'
      break
    default:
      classes += 'bg-white text-dark'
      break
  }
  return classes
})
const buttonId = computed(() => {
  return attr.id || `memberhub-register-social-${ssoAuthLabel.value}`
})
const ssoAuthLabel = computed(() => {
  switch (props.ssoType) {
    case memberhubConstants.GOOGLE_AUTH:
      return 'google'
    case memberhubConstants.FACEBOOK_AUTH:
      return 'facebook'
    case memberhubConstants.LINKEDIN_AUTH:
      return 'linkedin'
    case memberhubConstants.APPLE_AUTH:
      return 'apple'
    default:
      return 'google'
  }
})
const ssoAuthLabelFormatted = computed(() => {
  return ssoAuthLabel.value.charAt(0).toUpperCase() + ssoAuthLabel.value.slice(1)
})
const ssoIcon = computed(() => {
  switch (props.ssoType) {
    case memberhubConstants.GOOGLE_AUTH:
      return '/icons/social/google.svg'
    case memberhubConstants.FACEBOOK_AUTH:
      return '/icons/social/facebook-white.svg'
    case memberhubConstants.LINKEDIN_AUTH:
      return '/icons/social/linkedin-square.svg'
    case memberhubConstants.APPLE_AUTH:
      return '/icons/social/apple.svg'
    default:
      return '/icons/social/google.svg'
  }
})

/*
 * Button redirection handler
 * @param e
 */
function redirect (e) {
  if (props.enableTracking) {
    $tracking.click(e)
  }
  if (props.targetUrl && !props.disabled) {
    $bus.emit('redirection', { path: props.targetUrl })
  } else {
    emits('click', e)
  }
}
</script>

<style scoped>

</style>
