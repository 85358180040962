<template>
  <div
    class="mx-auto w-full sm:mt-16 mt-8 sm:block flex"
  >
    <AtomsDashboardAuthSuccessScreen v-if="successScreen" @load-dashboard="psLogin" />
    <div v-else class="flex flex-col w-full">
      <h1 class="text-dark sm:text-xl text-lg">
        <strong class="font-bold">{{ userInformation.name }}, let’s set up your account</strong>
      </h1>
      <div class="py-2 mb-4 text-sm text-gray-80">
        {{ userInformation.email }}
        <atoms-popover-v2
          class="inline-flex align-middle"
          theme="default"
          position="bottom"
          has-close
          action-type="click"
          small-close
          radius="md"
        >
          <div>
            <IconsInfoCircle
              class="pointer-events-none h-4 w-4"
              type="attention"
              fill="#193560"
            />
          </div>
          <template #content>
            <p class="text-xs text-dark font-normal">
              Contact customer support to update your email. <a :href="`tel:${contactNumber}`" class="font-bold underline cursor-pointer">Call
                {{ contactNumber }}</a>
            </p>
          </template>
        </atoms-popover-v2>
      </div>
      <form @submit.prevent="saveUser">
        <AtomsInput
          v-model="model.password"
          :validate="validateForm"
          v-bind="schema"/>
        <MoleculesFormPasswordMeter
          v-if="model.password && schema.passwordMeter"
          :schema-obj="schema"
        />
        <div class="grid grid-cols-1 mt-4">
          <atoms-button
            type="submit"
            size="medium"
          >
            CONFIRM
          </atoms-button>
        </div>
      </form>
      <p class="my-2 text-sm font-normal text-center text-dark">
        Already a member?
        <atoms-button
          id="auth_login_btn"
          class="p-0 !text-base !inline-flex"
          size="small"
          theme="tertiary"
          enable-tracking
          to="/login/"
        >
          Log in
        </atoms-button>
      </p>
      <div
        class="font-normal text-gray-80 text-xs text-center px-0 pb-8 leading-[1.125rem]"
      >
        By clicking ‘CONFIRM', I acknowledge that I have read and agree to the<br />
        <nuxt-link
          ref="terms_of_use"
          class="underline cursor-pointer"
          to="/terms-of-use/"
          target="_blank"
        >
          <strong>Terms of Use</strong>
        </nuxt-link>, the
        <nuxt-link
          ref="privacy_policy"
          to="/privacy-policy/"
          target="_blank"
          class="underline cursor-pointer"
        >
          <strong>Privacy Policy</strong>
        </nuxt-link> and the
        <nuxt-link
          ref="collection_notice"
          to="/collection-notice/"
          target="_blank"
          class="underline cursor-pointer"
        >
          <strong>Collection Notice</strong>
        </nuxt-link>.
      </div>
    </div>
  </div>
</template>

<script setup>
import { VERTICALS } from '~/utils/constants/verticals.constants'
import { VERTICAL } from '~/helpers/vertical.constants'
import { passwordValidator } from '~/helpers/validator'
import memberhubConstants from '../../../../constants/memberhub.constants'
defineOptions({
  name: 'OrganismsAuthenticationPostSale'
})

const props = defineProps({
  userInformation: {
    type: Object,
    required: true,
    validator: value => {
      return value.name && value.email
    }
  }
})

const vaultStore = useVaultStore()
const authStore = useUserStore()
const route = useRoute()
const schema = ref({
  name: 'password',
  type: 'password',
  theme: 'light',
  placeholder: 'New Password',
  label: 'New Password',
  isSuccess:true,
  darkPlaceholder:true,
  modelType: 'text',
  hasPercentage: false,
  hasCurrency: false,
  passwordMeter: true,
  fieldLabel: '<strong>Set your password here <span class="text-alert-error-stroke">*</span></strong>'
})
const model = ref({
  password: null
})

const successScreen = ref(false)

const contactNumber = VERTICALS[VERTICAL.HEALTH_INSURANCE].phoneNumber

const successScreenTimeout = ref(null)

/*
 * Save user on post sale
 * @param payload
 */
function saveUser (payload = {}) {
  if (!validateForm()) return

  const { email } = props.userInformation
  const { from = 'email' } = route.query
  const { password } = model.value
  payload.formData = {}
  Object.assign(payload.formData, { email, from, password })
  payload.schema = schema.value

  vaultStore.savePurchasedUser(payload).then(res => {
    if (res.user_id) {
      successScreen.value = true
      successScreenTimeout.value = setTimeout(psLogin, 5000)
    }
  })
}

/*
 * Post sale auto login
 */
function psLogin () {
  clearTimeout(successScreenTimeout.value)
  authStore.login({
    formData: {
      email: props.userInformation.email,
      password: model.value.password
    },
    psSignUp: true
  })
}

/*
 * Validates the form by checking the password field and updating the schema with the validation results.
 *
 * @returns {boolean} - Returns true if the form is valid, otherwise false.
 */
function validateForm () {
  schema.value.errorMessage = ''
  schema.value.isSuccess = false

  if (!model.value.password) {
    schema.value.errorMessage = 'Please enter a valid password'
    return false
  }

  if (schema.value.passwordMeter && model.value.password) {
    const result = validatePasswordMeter(model.value.password)
    schema.value.error = {
      errorObj: result,
      status: true
    }
    schema.value.isSuccess = result.valid
  } else {
    schema.value.isSuccess = true
  }

  return schema.value.isSuccess
}

/*
 * Validates the password based on various criteria and returns the validation results.
 *
 * @param {string} password - The password to be validated.
 * @returns {Object} - An object containing the validation results and password meter requirements.
 */
function validatePasswordMeter (password) {
  const result = {}
  const checks = {
    eightCharCheck: passwordValidator(password, /^.{8,}$/),
    sixCharCheck: passwordValidator(password, /^.{6,}$/),
    lowercaseCheck: passwordValidator(password, /[a-z]/),
    uppercaseCheck: passwordValidator(password, /[A-Z]/),
    numberCheck: passwordValidator(password, /\d/),
    specialCharCheck: passwordValidator(password, /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/)
  }

  result.passwordMeterRequires = getPasswordMeterRequirements(checks)
  const conditionsSatisfied = Object.values(checks).slice(2).filter(Boolean).length

  if (checks.eightCharCheck && conditionsSatisfied === 4) {
    result.valid = true
    result.passwordMeter = memberhubConstants.PASSWORD_METER.STRONG
    result.passwordMeterRequires = []
  } else if (checks.eightCharCheck && conditionsSatisfied >= 3) {
    result.valid = true
    result.passwordMeter = memberhubConstants.PASSWORD_METER.GOOD
  } else if (checks.sixCharCheck && conditionsSatisfied >= 3) {
    result.valid = false
    result.passwordMeter = memberhubConstants.PASSWORD_METER.WEAK
  } else {
    result.passwordMeter = memberhubConstants.PASSWORD_METER.POOR
    result.valid = false
  }

  return result
}

/*
 * Gets the list of unmet password requirements based on various checks.
 *
 * @param {Object} checks - An object containing the results of various password validation checks.
 * @param {boolean} checks.eightCharCheck - Indicates if the password meets the minimum length requirement of 8 characters.
 * @param {boolean} checks.lowercaseCheck - Indicates if the password contains at least one lowercase letter.
 * @param {boolean} checks.uppercaseCheck - Indicates if the password contains at least one uppercase letter.
 * @param {boolean} checks.specialCharCheck - Indicates if the password contains at least one special character.
 * @param {boolean} checks.numberCheck - Indicates if the password contains at least one number.
 * @returns {Array} - An array of unmet password requirements.
 */
function getPasswordMeterRequirements (checks) {
  const requirements = []
  if (!checks.eightCharCheck) requirements.push(memberhubConstants.PASSWORD_METER_REQUIRED_KEYS.MIN_LENGTH)
  if (!checks.lowercaseCheck) requirements.push(memberhubConstants.PASSWORD_METER_REQUIRED_KEYS.LOWERCASE)
  if (!checks.uppercaseCheck) requirements.push(memberhubConstants.PASSWORD_METER_REQUIRED_KEYS.UPPERCASE)
  if (!checks.specialCharCheck) requirements.push(memberhubConstants.PASSWORD_METER_REQUIRED_KEYS.SPECIAL_CHAR)
  if (!checks.numberCheck) requirements.push(memberhubConstants.PASSWORD_METER_REQUIRED_KEYS.NUMBER)
  return requirements
}
</script>

<style scoped>

</style>
